// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---ecosistemas-src-templates-base-base-template-tsx": () => import("./../../../../ecosistemas/src/templates/base/base.template.tsx" /* webpackChunkName: "component---ecosistemas-src-templates-base-base-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chat-bot-asesor-js": () => import("./../../../src/pages/chat-bot-asesor.js" /* webpackChunkName: "component---src-pages-chat-bot-asesor-js" */),
  "component---src-pages-error-explorer-js": () => import("./../../../src/pages/error-explorer.js" /* webpackChunkName: "component---src-pages-error-explorer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resultados-js": () => import("./../../../src/pages/resultados.js" /* webpackChunkName: "component---src-pages-resultados-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-data-update-js": () => import("./../../../src/templates/data-update.js" /* webpackChunkName: "component---src-templates-data-update-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-internal-alternatives-js": () => import("./../../../src/templates/internal-alternatives.js" /* webpackChunkName: "component---src-templates-internal-alternatives-js" */),
  "component---src-templates-internal-canales-js": () => import("./../../../src/templates/internal-canales.js" /* webpackChunkName: "component---src-templates-internal-canales-js" */),
  "component---src-templates-internal-content-page-js": () => import("./../../../src/templates/internal-content-page.js" /* webpackChunkName: "component---src-templates-internal-content-page-js" */),
  "component---src-templates-internal-content-page-solutions-js": () => import("./../../../src/templates/internal-content-page-solutions.js" /* webpackChunkName: "component---src-templates-internal-content-page-solutions-js" */),
  "component---src-templates-internal-corporativo-accionista-js": () => import("./../../../src/templates/internal-corporativo-accionista.js" /* webpackChunkName: "component---src-templates-internal-corporativo-accionista-js" */),
  "component---src-templates-internal-current-investments-js": () => import("./../../../src/templates/internal-current-investments.js" /* webpackChunkName: "component---src-templates-internal-current-investments-js" */),
  "component---src-templates-internal-leyes-decretos-internal-leyes-y-decretos-js": () => import("./../../../src/templates/internal-leyes-decretos/internal-leyes-y-decretos.js" /* webpackChunkName: "component---src-templates-internal-leyes-decretos-internal-leyes-y-decretos-js" */),
  "component---src-templates-internal-marketplace-js": () => import("./../../../src/templates/internal-marketplace.js" /* webpackChunkName: "component---src-templates-internal-marketplace-js" */),
  "component---src-templates-internal-talento-si-hay-js": () => import("./../../../src/templates/internal-talento-si-hay.js" /* webpackChunkName: "component---src-templates-internal-talento-si-hay-js" */),
  "component---src-templates-mapa-sitte-js": () => import("./../../../src/templates/mapa-sitte.js" /* webpackChunkName: "component---src-templates-mapa-sitte-js" */),
  "component---src-templates-salesforce-template-js": () => import("./../../../src/templates/salesforce-template.js" /* webpackChunkName: "component---src-templates-salesforce-template-js" */)
}

