/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { createStore } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import index from './src/reducers/index'
import { FlexBoxProvider } from './src/components/FlexBox/FlexBox'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import './src/assets/styles/global.scss'

ReactGA.initialize(process.env.GA_KEY)

localStorage.setItem('alert', false)
if (process.env.MODAL_SURVEY === 'true') {
  setTimeout(() => {
    localStorage.setItem('alert', true)
  }, 120000)
}
let store = createStore(index, devToolsEnhancer())

let ua = window.navigator.userAgent
let msie = ua.indexOf('MSIE ')
if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
  // If Internet Explorer, return version number
  if (window.location.href.indexOf('error-explorer') === -1) {
    window.location.href =
      process.env.PATH_PREFIX === ''
        ? window.location.origin + `/error-explorer/`
        : window.location.origin +
          '/' +
          process.env.PATH_PREFIX +
          '/' +
          `error-explorer/`
  }
} else if (msie > 0) {
  // If Internet Explorer, return version number
  if (window.location.href.indexOf('error-explorer') === -1) {
    window.location.href =
      process.env.PATH_PREFIX === ''
        ? window.location.origin + `/error-explorer/`
        : window.location.origin +
          '/' +
          process.env.PATH_PREFIX +
          '/' +
          `error-explorer/`
  }
}

const prefix =
  process.env.PATH_PREFIX === '' ? '/' : '/' + process.env.PATH_PREFIX + '/'

if (window.location.pathname.indexOf('home') < 0) {
  localStorage.setItem('accessibility-hight-contrast', false)
}

export const wrapRootElement = ({ element }) => {
  return (
    <FlexBoxProvider>
      <Helmet>
        {window.location.protocol === 'https:' && (
          <link
            rel="canonical"
            href={window.location.href}
            data-baseprotocol={window.location.protocol}
            data-basehost={window.location.host}
          />
        )}
      </Helmet>
      <Provider store={store}>{element}</Provider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </FlexBoxProvider>
  )
}
