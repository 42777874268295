module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-head/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W3JLHBR","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../ecosistemas/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Protección - Fondo de Pensiones y Cesantías en Colombia","short_name":"Protección","short_url":"https://www.proteccion.com","start_url":"/","background_color":"#E3E829","theme_color":"#E3E829","display":"fullscreen","icon":"src/assets/images/Favicon/New-FavIcon-Proteccion.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2796d2f76dab560e48c7f1c9e2741d45"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"defaultCrumb":{"location":{"pathname":"/home/"},"crumbLabel":"Home","crumbSeparator":"  >  "},"useClassNames":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
